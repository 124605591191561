import React from 'react'
import Modal from 'react-modal'
import page from '../page'

const modalCustomStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    fontFamily           : 'SourceSansPro',
    minWidth                : '275px'
  },
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : 0,
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(0, 0, 0, 0.8)'
  }
}

export default class LandingPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalIsOpen: false
    };
    this.closeModal = this.closeModal.bind(this);

  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  renderBanner() {
    var bannerStyle
    if (this.props.aggregator.banner_type === 'banner_image') {
      bannerStyle = {backgroundImage: `url(${this.props.background_image})`}
    } else {
      bannerStyle = {backgroundColor: `${this.props.aggregator.homepage_banner_color}`}
    }
    return bannerStyle
  }

  renderModal() {
    return (
      <Modal isOpen={this.state.modalIsOpen}
             style={modalCustomStyles}
             contentLabel="Age Modal"
             ariaHideApp={false}>
        <h5 className='modal-title'>How old are you?</h5>
        <button className='age-range-button'
                onClick={ () => page.submissionFail() }
                style={{backgroundColor: `${this.props.aggregator.primary_color}`,
                        color: `${this.props.aggregator.primary_font_color}`}} >12 and Under</button>
        <button className='age-range-button'
                onClick={ () => page.underEighteenFlow() }
                style={{backgroundColor: `${this.props.aggregator.primary_color}`,
                        color: `${this.props.aggregator.primary_font_color}`}} >13 to 17</button>
        <button className='age-range-button'
                onClick={() => page.overEighteenFlow() }
                style={{backgroundColor: `${this.props.aggregator.primary_color}`,
                        color: `${this.props.aggregator.primary_font_color}`}} >18 and Over</button>
        <button className='age-range-button'
                onClick={this.closeModal}>Cancel</button>
      </Modal>
    )
  }

  render() {
    const year = new Date(Date.now()).getFullYear()

    return (
      <div id='submissions' style={{backgroundColor: `${this.props.aggregator.background_color}`}} >
        { this.renderModal() }
        <div id="index-container" style={{maxWidth: ((this.props.aggregator.banner_type === 'banner_image')? 1400 : '100%')}}>
          <div id="intro-container" style={this.renderBanner()} >
            <div className="row justify-content-center">
              <img src={this.props.logo} className="caution-man"></img>
            </div>
            <div className='row justify-content-center'>
              <section id="intro-text"
                        style={{fontFamily: `${this.props.aggregator.primary_font}`,
                                color: `${this.props.aggregator.homepage_title_color}`}}>
                Submit your clip to {this.props.aggregator.name}
              </section>
            </div>
          </div>
          <div id="sub-content-container" className="container-fluid">
            <div className="row justify-content-center">
              <p id="intro-description-text"
                  style={{color: `${this.props.aggregator.homepage_font_color}`}}>
                {this.props.aggregator.homepage_description}
              </p>
            </div>
            <div className="row justify-content-center">
              <img src={this.props.homepage_small_image} id="oscar-star" className="sub-content-logo"></img>
            </div>
            <div className="row justify-content-center">
              <p className="sub-content-body-text"
                style={{color: `${this.props.aggregator.homepage_font_color}`}}>{this.props.aggregator.credit_description}</p>
            </div>
            <div className="row justify-content-center">
              <button id="submit-button"
                      onClick={() => this.setState({modalIsOpen: true})}
                      style={{backgroundColor: `${this.props.aggregator.primary_color}`,
                              fontFamily: `${this.props.aggregator.primary_font}`,
                              color: `${this.props.aggregator.primary_font_color}`}} >
                submit your clip!
              </button>
            </div>
            <footer className="footer">
              <div className="row justify-content-center">
                <ul className="list-inline">
                  <li className="list-inline-item"><a href="/terms" target="__blank" style={{color: `${this.props.aggregator.homepage_font_color}`}}>Terms</a></li>
                  <li className="list-inline-item"><a href="/privacy" target="__blank" style={{color: `${this.props.aggregator.homepage_font_color}`}}>Privacy</a></li>
                  { (this.props.aggregator.youtube != "") ? (<li className="list-inline-item"><a href={this.props.aggregator.youtube} target="__blank" style={{color: `${this.props.aggregator.homepage_font_color}`}}>Youtube</a></li>) : ''}
                  { (this.props.aggregator.facebook != "") ? (<li className="list-inline-item"><a href={this.props.aggregator.facebook} target="__blank" style={{color: `${this.props.aggregator.homepage_font_color}`}}>Facebook</a></li>) : ''}
                  { (this.props.aggregator.instagram != "") ? (<li className="list-inline-item"><a href={this.props.aggregator.instagram} target="__blank" style={{color: `${this.props.aggregator.homepage_font_color}`}}>Instagram</a></li>) : ''}
                  { (this.props.aggregator.tiktok != "") ? (<li className="list-inline-item"><a href={this.props.aggregator.tiktok} target="__blank" style={{color: `${this.props.aggregator.homepage_font_color}`}}>TikTok</a></li>) : ''}
                </ul>
              </div>
              <div className="row justify-content-center">
                <ul className='list-inline'>
  <li className='copyright' style={{color: `${this.props.aggregator.homepage_font_color}`}}>&copy; {this.props.aggregator.name} {year}</li>
                </ul>
              </div>
            </footer>
          </div>
        </div>
      </div>
    )
  }
}
