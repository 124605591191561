const page = {
  landingPage: () => window.location.href = "/",

  underEighteenFlow: () => window.location.href = "/submissions/new?over_eighteen=false",

  overEighteenFlow: () => window.location.href = "/submissions/new?over_eighteen=true",

  submissionConfirmation: (isOverEighteen) => window.location.href = `/submissions/confirmation?over_eighteen=${isOverEighteen}`,

  submissionFail: () => window.location.href = '/submissions/fail',
}

export default page
